import request from '@/utils/request'


// 查询广告-形式列表
export function listAdvertisingType(query) {
  return request({
    url: '/platform/advertising-type/list',
    method: 'get',
    params: query
  })
}

// 查询广告-形式分页
export function pageAdvertisingType(query) {
  return request({
    url: '/platform/advertising-type/page',
    method: 'get',
    params: query
  })
}

// 查询广告-形式详细
export function getAdvertisingType(data) {
  return request({
    url: '/platform/advertising-type/detail',
    method: 'get',
    params: data
  })
}

// 新增广告-形式
export function addAdvertisingType(data) {
  return request({
    url: '/platform/advertising-type/add',
    method: 'post',
    data: data
  })
}

// 修改广告-形式
export function updateAdvertisingType(data) {
  return request({
    url: '/platform/advertising-type/edit',
    method: 'post',
    data: data
  })
}

// 删除广告-形式
export function delAdvertisingType(data) {
  return request({
    url: '/platform/advertising-type/delete',
    method: 'post',
    data: data
  })
}
